import React from "react"
import countdown from "countdown"
import "./countdown.css"

const weddingDay = new Date("May 20, 2022 18:30:00")
const counting = countdown(null, weddingDay)

const Countdown = () => (
  <ul className="countdown">
    <li className="timeStamps">
      <div className="timeStamp">
        <h4 className="months">{counting.months}</h4>
        <h4 className="time-label">MONTHS</h4>
      </div>
    </li>
    <li className="timeStamps">
      <div className="timeStamp">
        <h4 className="days">{counting.days}</h4>
        <h4 className="time-label">DAYS</h4>
      </div>
    </li>
    <li className="timeStamps">
      <div className="timeStamp">
        <h4 className="hours">{counting.hours}</h4>
        <h4 className="time-label">HOURS</h4>
      </div>
    </li>
  </ul>
)

export default Countdown
